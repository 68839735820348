<template>
  <a-form-item
    :required="required || this.image.switch"
  >
    <template slot="label">{{ title }}<slot name="title"></slot></template>
    <template v-if="!required">
      <small>{{ subject }} má {{ title.toLowerCase() }}:&nbsp;</small>
      <a-switch checkedChildren="Ano" unCheckedChildren="Ne" v-model="image.switch"
                @change="onImageSwitchChange"/>
    </template>
    <div v-if="required || this.image.switch">
      <a-row class="pb-5" v-if="isSelectable">
        <div>{{ ['multiple', 'product'].includes(type) ? 'Vybrané obrázky' : 'Vybraný obrázek' }}:</div>
        <template v-if="isSelected">
          <a-col :key="'selected-item'+item.iae_id" v-for="item in selectedItems" :xl="4" :md="6" :sm="8" :xs="12">
            <div
              :class="[$style.item, $style.active]">
              <img class="img-fluid img-size-6"
                   v-lazy="$uploadImagePath + item.iae_path + getImageSize(item, 6) + '.' + item.iae_format">
              <div :class="$style.itemControls">
                <span :class="$style['item-name']">{{ toImageName(item.iae_name) }}</span>
                <a-button type="primary" shape="circle" icon="delete" @click="removeSelectedImage(item)"></a-button>
                <a-tooltip title="Hlavní obrázek" v-if="type === 'product'">
                  <a-button class="ml-2" shape="circle" :type="isItemSelectedOne(item) ? 'primary' : 'default'" :icon="isItemSelectedOne(item) ? 'minus' : 'plus'" @click="updateSelectedOneImage(item)"></a-button>
                </a-tooltip>
              </div>
            </div>
          </a-col>
        </template>
        <a-empty v-else></a-empty>
      </a-row>

      Seznam obrázků:
      <a-row class="pb-5" type="flex">
        <a-col :xs="{span: 8}" :md="{span: 8}" :lg="{span: 4}">
          <a-select
            :allowClear="true"
            v-model="image.category_id"
            showSearch
            placeholder="-- Vyber kategorii --"
            :options="imageCategories.map(x => { return { value: x.icy_id, label: x.icy_name }})"
            :loading="imageCategoriesLoading"
            optionFilterProp="children"
            :filterOption="filterOption"
          />
        </a-col>
        <a-col :xs="{span: 14, offset: 2}" :md="{span: 14, offset: 2}" :lg="{span: 8, offset: 4}">
          <a-input-search
            placeholder="Hledat... (alespoň 3 znaky)"
            v-model="image.search"
          />
        </a-col>
      </a-row>
      <div v-if="images.data.length > 0">
        <a-row>
          <div class="text-right">Zobrazeno 1 - {{ imagesShownCount }} z celkem {{ images.count }} obrázků</div>

          <a-checkbox-group v-if="type === 'product'"
                            v-decorator="[decoratorName, {required: required || this.image.switch, message: 'Obrázek musí být vyplněn!'}]"
                            name="image1" :class="$style.gallery">
              <a-col :key="'all'+index" v-for="(item, index) in images.data" :xl="4" :md="6" :sm="8" :xs="12">
                <div :class="{[$style.active]: isItemSelected(item.iae_id), [$style.item]: 1 === 1, [$style.withControls]: 1 === 1}">
                  <img class="img-fluid img-size-6" v-lazy="$uploadImagePath + item.iae_path + getImageSize(item, 6) + '.' + item.iae_format">
                  <div :class="$style.itemControls">
                    <span :class="$style['item-name']">{{ toImageName(item.iae_name) }}</span>
                    <a-form-item
                      label=""
                      validate-status=""
                      help=""
                    >
                      <a-checkbox class="mr-2" :class="$style['ant-radio-wrapper']" :value="item.iae_id" @click="updateSelectedMultipleImage(item)"/>
                      <a-tooltip title="Hlavní obrázek">
                        <a-button size="small" shape="circle" :type="isItemSelectedOne(item) ? 'primary' : 'default'" :icon="isItemSelectedOne(item) ? 'minus' : 'plus'" @click="updateSelectedOneImage(item)"></a-button>
                      </a-tooltip>
                    </a-form-item>
                  </div>
                </div>
              </a-col>
          </a-checkbox-group>
          <template v-else-if="type === 'numeric-radio'">
            <a-radio-group v-decorator="[decoratorName]" name="image" :class="$style.gallery">
              <a-col :key="'all'+index" v-for="(item, index) in images.data" :xl="4" :md="6" :sm="8" :xs="12">
                <div :class="{[$style.active]: isItemSelected(item.iae_id), [$style.item]: 1 === 1, [$style.withControls]: 1 === 1}">
                  <img class="img-fluid img-size-6" v-lazy="$uploadImagePath + item.iae_path + getImageSize(item, 6) + '.' + item.iae_format">
                  <div :class="$style.itemControls">
                    <span :class="$style['item-name']">{{ toImageName(item.iae_name) }}</span>
                    <a-tooltip placement="bottom" title="Číslo položky">
                      <a-input-number
                        v-if="form.getFieldValue('items') !== undefined && form.getFieldValue('items').length > 0"
                        :min="1"
                        :max="form.getFieldValue('items').length"
                        size="small"
                        style="margin-right: 5px;"
                        v-decorator="[`itemNumber[${index}]`, { initialValue: 1 }]"
                      />
                    </a-tooltip>
                    <a-button size="small" icon="edit" @click="changeImage(item, form.getFieldValue('itemNumber['+ index + ']') || 1)"></a-button>
                    <div>
                      <a-tooltip placement="bottom" title="Pozadí">
                        <a-radio :class="$style['ant-radio-wrapper']" :value="item.iae_id" @click="updateSelectedImage(item)"/>
                      </a-tooltip>
                    </div>
                  </div>
                </div>
              </a-col>
            </a-radio-group>
          </template>
          <template v-else-if="type === 'numeric'">
            <a-col :key="'all'+index" v-for="(item, index) in images.data" :xl="4" :md="6" :sm="8" :xs="12">
              <div :class="[$style.item, $style.withControls]">
                <img class="img-fluid img-size-6" v-lazy="$uploadImagePath + item.iae_path + getImageSize(item, 6) + '.' + item.iae_format">
                <div :class="$style.itemControls">
                  <span :class="$style['item-name']">{{ toImageName(item.iae_name) }}</span>
                  <a-tooltip placement="bottom" title="Číslo kroku">
                    <a-input-number
                      v-if="form.getFieldValue('items') !== undefined && form.getFieldValue('items').length > 0"
                      :min="1"
                      :max="form.getFieldValue('items').length"
                      size="small"
                      style="margin-right: 5px;"
                      v-decorator="[`itemNumber[${index}]`, { initialValue: 1 }]"
                    />
                  </a-tooltip>
                  <a-button size="small" icon="edit" @click="changeImage(item, form.getFieldValue('itemNumber['+ index + ']') || 1)"></a-button>
                </div>
              </div>
            </a-col>
          </template>
          <a-radio-group v-if="type === 'single'"
            v-decorator="[decoratorName, {required: required || this.image.switch, message: 'Obrázek musí být vyplněn!'}]"
            name="image" :class="$style.gallery">
            <a-col :key="index" v-for="(item, index) in images.data" :xl="4" :md="6" :sm="8" :xs="12">
              <div
                :class="{[$style.active]: isItemSelected(item.iae_id), [$style.item]: 1 === 1, [$style.withControls]: 1 === 1}">
                <img class="img-fluid img-size-6"
                     v-lazy="$uploadImagePath + item.iae_path + getImageSize(item, 6) + '.' + item.iae_format">
                <div :class="$style.itemControls">
                  <span :class="$style['item-name']">{{ toImageName(item.iae_name) }}</span>
                  <a-radio :class="$style['ant-radio-wrapper']" :value="item.iae_id" @click="updateSelectedImage(item)"/>
                </div>
              </div>
            </a-col>
          </a-radio-group>
          <a-checkbox-group v-else-if="type === 'multiple'"
                            v-decorator="[decoratorName, {required: required || this.image.switch, message: 'Obrázek musí být vyplněn!'}]"
                            name="image" :class="$style.gallery">
            <a-col :key="index" v-for="(item, index) in images.data" :xl="4" :md="6" :sm="8" :xs="12">
              <div
                :class="{[$style.active]: isItemSelected(item.iae_id), [$style.item]: 1 === 1, [$style.withControls]: 1 === 1}">
                <img class="img-fluid img-size-6"
                     v-lazy="$uploadImagePath + item.iae_path + getImageSize(item, 6) + '.' + item.iae_format">
                <div :class="$style.itemControls">
                  <span :class="$style['item-name']">{{ toImageName(item.iae_name) }}</span>
                  <a-checkbox :class="$style['ant-radio-wrapper']" :value="item.iae_id" @click="updateSelectedImage(item)"/>
                </div>
              </div>
            </a-col>
          </a-checkbox-group>
        </a-row>
        <a-row class="pb-5 pt-5" type="flex" justify="center">
          <a-col :span="8">
            <a-button :loading="image.loading" v-if="imageNextCount > 0" type="primary" class="d-block m-auto"
                      @click="onNextImagesClick">Načíst dalších {{ imageNextCount }} (zbývá {{ images.count - images.data.length }})
            </a-button>
          </a-col>
        </a-row>
      </div>
      <a-empty v-else>
        <a-spin v-if="!image.loaded"/>
      </a-empty>
    </div>
  </a-form-item>
</template>

<script>
import imageMixins from '@/components/MyComponents/Gallery/image-mixins'
import imageSizeMixin from '@/components/MyComponents/Gallery/image-size-mixin'

export default {
  name: 'my-form-image-item',
  mixins: [imageMixins, imageSizeMixin],
  props: {
    title: String,
    image: Object,
    subject: String,
    changeImage: Function,
    images: Object,
    imageNextCount: Number,
    onNextImagesClick: Function,
    onImageSwitchChange: Function,
    decoratorName: String,
    form: Object,
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'single',
      validation: function (value) {
        return ['single', 'multiple', 'numeric', 'numeric-radio', 'product'].indexOf(value) !== -1
      },
    },
  },
  data() {
    return {
      imageCategoriesLoading: false,
    }
  },
  methods: {
    isItemSelected(id) {
      let found = false
      this.selectedItems.some(val => {
        if (id === val.iae_id) {
          found = true
          return true
        }
      })
      return found
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
    },
    removeSelectedImage: function (item) {
      if (['multiple', 'product'].includes(this.type)) {
        let index = this.image.selected.indexOf(item)
        if (index !== -1) {
          this.image.selected.splice(index, 1)
        }
        if (this.form.getFieldValue(this.decoratorName) !== undefined) {
          index = this.form.getFieldValue(this.decoratorName).indexOf(item)
          this.form.getFieldValue(this.decoratorName).splice(index, 1)
        }
        if (this.type === 'product' && this.isItemSelectedOne(item)) {
          this.image.selectedOne = null
        }
      } else if (['single', 'numeric-radio'].includes(this.type)) {
        this.image.selectedOne = null
        if (this.form.getFieldValue(this.decoratorName) !== undefined) {
          this.form.setFieldsValue({ [this.decoratorName]: null })
        }
      }
    },
    updateSelectedMultipleImage: function(item) {
      const index = this.isItemInSelected(item)
      if (index === -1) {
        this.image.selected.push(item)
      } else {
        if (this.type === 'product' && this.isItemSelectedOne(item)) {
          this.image.selectedOne = null
        }
        this.image.selected.splice(index, 1)
      }
    },
    isItemInSelected: function (item) {
      let index = -1
      this.image.selected.some((val, key) => {
        if (val.iae_id === item.iae_id) {
          index = key
          return true
        }
      })
      return index
    },
    updateSelectedOneImage: function(item) {
      if (this.type === 'product' && (this.image.selectedOne === undefined || this.image.selectedOne === null || this.image.selectedOne.iae_id !== item.iae_id)) {
        if (this.isItemInSelected(item) === -1) {
          if (Array.isArray(this.form.getFieldValue(this.decoratorName))) {
            const items = this.form.getFieldValue(this.decoratorName)
            items.push(item.iae_id)
            this.form.setFieldsValue({ [this.decoratorName]: items })
          } else {
            this.form.setFieldsValue({ [this.decoratorName]: [item.iae_id] })
          }
          this.image.selected.push(item)
        }
      }
      this.image.selectedOne = item
    },
    updateSelectedImage: function (item) {
      if (this.type === 'multiple') {
        this.updateSelectedMultipleImage(item)
      } else if (['single', 'numeric-radio'].includes(this.type)) {
        this.updateSelectedOneImage(item)
      }
    },
    loadImageCategories: function() {
      this.loading = true
      this.imageCategoriesLoading = true
      this.$store.dispatch('imageCategory/getList')
        .catch(() => {})
        .finally(() => {
          this.loading = false
          this.imageCategoriesLoading = false
        })
    },
    isItemSelectedOne: function (item) {
      return !(this.image.selectedOne === undefined || this.image.selectedOne === null || this.image.selectedOne.iae_id !== item.iae_id)
    },
  },
  computed: {
    isSelectable: function () {
      return ['multiple', 'single', 'numeric-radio', 'product'].includes(this.type)
    },
    isSelected: function () {
      if (['multiple', 'product'].includes(this.type)) {
        return this.image.selected.length !== 0
      }
      if (['single', 'numeric-radio'].includes(this.type)) {
        return this.image.selectedOne !== undefined && this.image.selectedOne !== null
      }
      return false
    },
    selectedItems: function () {
      if (['multiple', 'product'].includes(this.type)) {
        return this.image.selected
      }
      if (['single', 'numeric-radio'].includes(this.type)) {
        return this.image.selectedOne === null || this.image.selectedOne === undefined ? [] : [this.image.selectedOne]
      }
      return []
    },
    imageCategories: function () {
      return this.$store.getters['imageCategory/filteredItems']('')
    },
    imagesShownCount: function () {
      const count = this.image.page * this.images.per_page
      if (count > this.images.count) {
        return this.images.count
      }
      return count
    },
  },
  created() {
    if (this.required) {
      this.image.switch = true
      this.onImageSwitchChange(true)
    }
    this.loadImageCategories()
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>

export default {
  methods: {
    debounceRequest(func, delay) {
      let debounceTimer
      return function() {
        const context = this
        const args = arguments
        clearTimeout(debounceTimer)
        debounceTimer = setTimeout(() => func.apply(context, args), delay)
      }
    },
  },
}
